import React, { useRef, useState } from 'react';
import isEmpty from '../../../Utilities/is_empty';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { MenuSections } from '../Common/MenuArea';
import RatingPopUp, { RatingPopUpRef } from '../../../Common/Popups/RatingPopUp';
import { FoodItem } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { addToFavourites, removeFromFavourites } from '../../../Actions/menuActions';
import { useDispatch } from 'react-redux';
import { LocalStorageCompany } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { AddressesActiveAttributes } from '../../../Interfaces/PayloadsAndResponses/Company';

type MenuSectionProps = {
  recentOrderIdForRating: number | undefined;
  remaining_budget?: string,
  isFutureDelivery?: boolean;
  per_user_copay_amount: string | number,
  fooditem: FoodItem,
  fromDashboard?: boolean,
  fromMarketplace?: boolean,
  pricesToShown: number | string,
  per_user_copay: 1 | 0,
  invitedMeeting?: boolean,
  description: string,
  sectionType: MenuSections,
  isBuffetMenu?: string,
}

const MenuSection = ({
  remaining_budget,
  recentOrderIdForRating,
  fooditem,
  // fromDashboard,
  sectionType,
  isFutureDelivery = false,
  // fromMarketplace = false,
  pricesToShown,
  invitedMeeting,
  description,
  per_user_copay,
  per_user_copay_amount,
  isBuffetMenu,
}: MenuSectionProps) => {

  const { isCompanyAdmin, isUnsubsidizedUser,loggedInUser } = getloggedInUser();
  const{id}=loggedInUser
  const [rating, setRating] = useState<number | null>(null);
  const [defaultFavoriteHover , setDefaultFavoriteHover] = useState<boolean>(false)
  const [favourited , setFavourited] = useState<boolean>(fooditem.user_favorite)
  const dispatch: any = useDispatch()

  const company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  const localAddress : AddressesActiveAttributes = JSON.parse(localStorage.getItem('address') || '{}');

  let ratingPopUpRef = useRef<RatingPopUpRef | null>(null);

  const getUserPaid = (price: number) => {
    let newPrice = 0;
    // const {per_user_copay, per_user_copay_amount} = this.props || {};
    let remainingBudgetCalculation = parseFloat(`${remaining_budget}`) < 0 ? 0 : parseFloat(`${remaining_budget}`);

    if (per_user_copay && parseFloat(`${per_user_copay_amount}`) >= 0) { // Check if Copay is On for Company and Copay is Present.
      if (parseFloat(`${remainingBudgetCalculation}`) >= price) {// Check if Remaining budget of Delivery is Greater or Equal to FoodItem Price.
        newPrice = parseFloat(`${per_user_copay_amount}`); // Because Copay is On and FoodItem is in budget so New Price for FoodItem will be Copay amount Present in Delivery Details API.
      } else {// Copay is On and Remaining budget of Delivery is Less than FoodItem Price.
        newPrice = price - parseFloat(`${remainingBudgetCalculation}`); // New Price is set to the difference b/w Fooditem Price and Remainng Budget for Delivery.
        if (remainingBudgetCalculation > 0 && remainingBudgetCalculation > +per_user_copay_amount) { // Remaining Budget is Present and is Greater than Copay Amount of Delivery.
          newPrice = newPrice + parseFloat(`${per_user_copay_amount}`); //Copay Amount is Added to Difference [parseFloat(price) - parseFloat(remaining_budget)].
        } else {// Remaining Budget is Less or Equal to Copay Amount of Delivery OR Remaining Budget is Consumed.
          newPrice = price; // Now New Price will be the only Price of Fooditem.
        }
        if (newPrice < +per_user_copay_amount) { // If Copay Amount is Greater than Above Calculated New Price Then Set back New Price to Fooditem Price.
          newPrice = price;
        }
      }
    } else {
      return '';
    }

    const foodItemPriceAfterCalculatingCopayAmount = (Number(newPrice).toFixed(2) === '0.00') ? '' : Number(newPrice).toFixed(2);
    const foodItemPriceAfterAddingStripeFee = foodItemPriceAfterCalculatingCopayAmount ? '$' + ((+foodItemPriceAfterCalculatingCopayAmount + .30) / (1 - .029)).toFixed(2) : foodItemPriceAfterCalculatingCopayAmount
    return foodItemPriceAfterAddingStripeFee;

    // Previous Price Calculation Formula Before Stripe Fee
    // return (Number(newPrice).toFixed(2) === '0.00') ? '' : '$' + Number(newPrice).toFixed(2);
  }

  const favouriteHandler = (foodItemId: number) => {
    let user_favorite = fooditem?.user_favorite
    if (user_favorite) {
        dispatch(removeFromFavourites(foodItemId))
    } else {
      dispatch(addToFavourites(foodItemId))
    }
  }

  const round = (num: number | string) => {
    const numberInput = parseFloat(`${num}`);
    if (numberInput % 1 === 0) {
      return numberInput;
    }
    return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(numberInput)
  }


  const transformText = (text: string) => {
    const maxLength = 18;

    if (text.length < maxLength) {
      // Calculate how many 'a's need to be added
      const addedChars = maxLength - text.length;
      // Return the number of 'a's added
      return 'a'.repeat(addedChars);
    }
    return '';
  }
  const foodItemNameSmall = transformText(fooditem?.name)

  const openRatingPopUpHandler = (rating: number) => {
    if (recentOrderIdForRating) {
      localStorage.setItem('order_id', `${recentOrderIdForRating}`);
      localStorage.setItem('rating_count', `${rating}`);
      if (ratingPopUpRef.current) {
        ratingPopUpRef.current.calculateRatingData();
        ratingPopUpRef.current.openModal();
      }
    }
  }

  const returnPercentRating = (rating: number, starNumber: number) => {
    if (rating >= starNumber) {
      return '100%'
    }
    else if ((!Number.isInteger(rating)) && (starNumber - rating) < 1) {
      return Number((rating - Math.floor(rating)) * 100) + '%';
    }
  }

  let Sections;
  // Final Price to be charged for Fooditem
  let foodItemFinalPrice = '0';
  if (isBuffetMenu) {
    foodItemFinalPrice = '' + pricesToShown
  } else {
    if (company?.self_signup === true || localAddress?.latitude) {
      // foodItemFinalPrice = (pricesToShown ? '$' + round(pricesToShown) : '');
      foodItemFinalPrice = `$${fooditem.price}`;
    } else {
      if (isCompanyAdmin) {
        foodItemFinalPrice = (pricesToShown ? '$' + round(pricesToShown) : '');
      } else if (isUnsubsidizedUser) {
        const foodItemPriceForUnsubsidizedUser = ((+pricesToShown + .30) / (1 - .029)).toFixed(2); // Added Stripe fee for Unsubsidized User,
        foodItemFinalPrice = (foodItemPriceForUnsubsidizedUser ? '$' + foodItemPriceForUnsubsidizedUser : '');
      } else {
        foodItemFinalPrice = (invitedMeeting && per_user_copay === 0 ? '' : getUserPaid(parseFloat(`${pricesToShown}`)));
      }
    }
  }
  //Fooditem Design Based on Parent Component
  Sections = <div className='d-flex h-100 flex-column menu-detail-fooditem-card cursor-pointer bg-white' style={{'position':'relative'}}  onMouseEnter={() => setDefaultFavoriteHover(true)} onMouseLeave={() => setDefaultFavoriteHover(false)}>
    {fooditem?.new_fooditem && <div className='new-tag-controller'>
      <p className='new-tag px-2'> New </p>
    </div>}
    {fooditem?.image?.medium?.url &&
      <div className='card-item-img' style={{ backgroundImage: `url(${fooditem.image.medium.url})`, height: '150px', width: '100%' }} />
    }
    <div className='px-3 flex-grow-1' >
      <div style={{ fontSize: '23px', 'position': 'relative' }} className='m-0 float-right d-inline-block '>
        {!invitedMeeting && id &&
          <div className={`rounded-circle d-flex favourite ${favourited ? '' : defaultFavoriteHover ? 'favourited' : 'notfavourited'} `} onClick={(e) => { e.stopPropagation(); favouriteHandler(fooditem.id); setFavourited(!favourited) }} >
            <p className='m-auto' >
              {favourited ?
                <i className='fas fa-heart fa-sm icon-favourites'></i>
                :
                defaultFavoriteHover &&
                <i className='far fa-heart fa-sm'></i>
              }
            </p>
          </div>
        }
      </div>
    </div>
    <div className='p-3 pb-3 flex-grow-1' >
      <div className='d-flex align-items-center justify-content-between'>
      <h6 style={{ width: +foodItemFinalPrice.split('$')[1] ? '70%' : '100%', textOverflow: !isEmpty(foodItemNameSmall) ? 'clip' : 'ellipsis' }} className='m-0 card-title d-inline-block fooditem-text-capitalize'>{fooditem?.name} {fooditem.name.length < 15 ? <span style={{ visibility: 'hidden' }}> placeholder</span> : <></>}</h6>
      <h6 className='m-0 float-right card-price-tag d-inline-block '>
        {foodItemFinalPrice}
      </h6>
      </div>
      <div>
        {sectionType === MenuSections.OrderItAgain ?
          <>
            <div
              onMouseLeave={() => setRating(0)}
              className='d-flex'
              style={{ width: 'fit-content' }}
              onClick={e => e.stopPropagation()}
            >
              {[1, 2, 3, 4, 5].map((star) =>
                <div key={star}
                  onMouseEnter={e => {
                    e.stopPropagation();
                    setRating(star)
                  }}
                  onClick={() => openRatingPopUpHandler(star)}>
                  <i className={`${(rating ?? 0) < star ? 'far' : 'fas'} fa-star`} style={{ fontSize: 20, margin: .5 }}> </i>
                </div>
              )}
            </div>
            <div style={{ color: '#212529', marginTop: 5 }}>
              Rate this item 
            </div>
          </>
          : <>
           {isFutureDelivery ? <>
              {[1, 2, 3, 4, 5].map(star =>
                <span key={star} className='star'>
                  <span className='rateValue' style={{ width: `${returnPercentRating(fooditem?.average_rating, star)}` }}></span>
                </span>
              )}
            </> : ''}
            {!isEmpty(fooditem.description) ? <div style={{ color: '#8B9898' }} className='mt-2 card-text d-block text-case'>
              {!isEmpty(fooditem.description) ? description : ''}
            </div> 
            : 
            <div className='mt-2 card-text d-block text-case'> </div> 
            }
          </>
        }
      </div>
    </div>
  </div>

  return <>
    {Sections}
    {sectionType === MenuSections.OrderItAgain ?
      <div onClick={e => e.stopPropagation()}>
        <RatingPopUp
          ref={(ref: RatingPopUpRef | null) => { ratingPopUpRef.current = ref }}
          forOrderItAgainRating
        />
      </div> : ''
    }
  </>
}

export default MenuSection;
